import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { AUTH_REQUIRED } from '@app/tokens';
import config from '@config';
import { UrlService } from '@services/url.service';
import { map, zip } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class StorageService {
  publicBucket: string = config.publicBucket;

  private readonly httpClient = inject(HttpClient);
  private readonly url = inject(UrlService);

  getResourceUrl(fileName: string, bucket = this.publicBucket) {
    return `https://s3.amazonaws.com/${bucket}/${fileName}`;
  }

  uploadFileToS3WithPresignedUrl(presignedUrl: string, file: File) {
    return this.httpClient.put(presignedUrl, file, {
      context: new HttpContext().set(AUTH_REQUIRED, false),
    });
  }

  uploadFilesToS3WithPresignedUrl(filesInfo: Array<{ file: File; presignedUrl: string }>) {
    return zip(filesInfo.map(fileInfo => this.uploadFileToS3WithPresignedUrl(fileInfo.presignedUrl, fileInfo.file)));
  }

  getSignedUploadUrl(fileName: string, bucket = this.publicBucket) {
    const url = `${this.url.MASTER_DATA_URL}/get-signed-url-for-file-upload?bucket=[bucketName]&filename=[filename]`
      .replace('[bucketName]', bucket)
      .replace('[filename]', fileName);
    return this.httpClient.get<{ url: string }>(url).pipe(map(response => response.url));
  }
}
