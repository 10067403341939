{
  "FED_API_ROOT": "fedApiRoot",
  "FED_API_KEY": "fedApiKey",
  "FED_SERIES_ID": "fedSeriesId",
  "ERROR_HANDLER_RECIPIENTS": [
    "suba@itlab.cz",
    "michalik@itlab.cz",
    "emago@monarchsp.com",
    "jwk@g.clemson.edu",
    "ranocha@itlab.cz",
    "nosek@itlab.cz"
  ],
  "ERROR_HANDLER_EMAIL_REPORT": true,
  "tenants": {
    "HZN": {
      "id": "HZN",
      "name": "Horizon"
    },
    "ALM": {
      "id": "ALM",
      "name": "Almirall"
    },
    "GLD": {
      "id": "GLD",
      "name": "Galderma"
    },
    "DMO": {
      "id": "DMO",
      "name": "Demo"
    },
    "BHC": {
      "id": "BHC",
      "name": "Bausch Health"
    },
    "INCY": {
      "id": "INCY",
      "name": "Incyte"
    }
  },
  "products": {
    "HZN": [
      {
        "ndc": "75987004005",
        "name": "75987004005 - PENNSAID 2%",
        "dosage": "2%"
      },
      {
        "ndc": "75987001003",
        "name": "75987001003 - DUEXIS"
      },
      {
        "ndc": "75987003004",
        "name": "75987003004 - VIMOVO"
      },
      {
        "ndc": "75987003104",
        "name": "75987003104 - VIMOVO"
      },
      {
        "ndc": "75987002001",
        "name": "75987002001 - RAYOS - 1mg",
        "dosage": "1mg"
      },
      {
        "ndc": "75987002101",
        "name": "75987002101 - RAYOS - 2mg",
        "dosage": "2mg"
      },
      {
        "ndc": "75987002201",
        "name": "75987002201 - RAYOS - 5mg",
        "dosage": "5mg"
      }
    ],
    "ALM": [
      {
        "ndc": "16110052660",
        "name": "Aczone 7.5% 60g",
        "dosage": "7.5%",
        "howSupplied": "60 gram pump",
        "quantityUnit": "tubes"
      },
      {
        "ndc": "16110024530",
        "name": "Seysara 60mg",
        "dosage": "60",
        "howSupplied": "60mg",
        "quantityUnit": "bottles"
      },
      {
        "ndc": "16110024630",
        "name": "Seysara 100mg",
        "dosage": "100",
        "howSupplied": "100mg",
        "quantityUnit": "bottles"
      },
      {
        "ndc": "16110024730",
        "name": "Seysara 150mg",
        "dosage": "150",
        "howSupplied": "150mg",
        "quantityUnit": "bottles"
      },
      {
        "ndc": "16110039105",
        "name": "Klisyri 250mg",
        "howSupplied": "5 packet in 1 carton",
        "quantityUnit": "cartons"
      },
      {
        "ndc": "16110039155",
        "name": "Klisyri 350mg",
        "howSupplied": "5 packet in 1 carton",
        "quantityUnit": "cartons"
      }
    ],
    "GLD": [
      {
        "ndc": "00299550004",
        "name": "CAPEX SHAMPOO 4 OZ",
        "brand": "CAPEX Shampoo 24x4oz US",
        "dosage": "0.01%",
        "howSupplied": "120ml"
      },
      {
        "ndc": "00299384904",
        "name": "CLOBEX SPRAY 4.25 FL OZ",
        "brand": "CLOBEX 0.05% Spray 24x4.25oz US",
        "dosage": "0.05%",
        "howSupplied": "125ml"
      },
      {
        "ndc": "00299382230",
        "name": "ORACEA 40 MCG CAP",
        "brand": "ORACEA 40 MG CAPSULE",
        "dosage": "40mg",
        "howSupplied": "40 MCG CAP"
      },
      {
        "ndc": "66993081530",
        "name": "DOXYCYCLINE",
        "brand": "DOXYCYCLINE USP 40mg Capsule 12x30ct US",
        "dosage": "40mg",
        "howSupplied": "40 MCG CAP"
      },
      {
        "ndc": "00299598035",
        "name": "MIRVASO PUMP",
        "brand": "MIRVASO(BRIMONIDINE).33%Gel+Pmp24x30g US",
        "dosage": "0.33%",
        "howSupplied": "30gm"
      },
      {
        "ndc": "00299590645",
        "name": "EPIDUO FORTE 45G PUMP",
        "brand": "EPIDUO FORTE 0.3%/2.5% Gel+Pmp 24x45g US",
        "dosage": "0.3-2.5%",
        "howSupplied": "45gm"
      },
      {
        "ndc": "00299382345",
        "name": "SOOLANTRA CREAM 1% 45G",
        "brand": "SOOLANTRA 1% Cream 24x45g US",
        "dosage": "1%",
        "howSupplied": "45gm"
      },
      {
        "ndc": "00299595030",
        "name": "TRILUMA CREAM 30G",
        "brand": "TRI-LUMA Cream 24x30g US",
        "dosage": "0.01-0.05-4%",
        "howSupplied": "30gm"
      },
      {
        "ndc": "00299593545",
        "name": "AKLIEF",
        "brand": "AKLIEF 50mcg .005% Cream 24x45g US",
        "dosage": "0.005%",
        "howSupplied": "45gm"
      },
      {
        "ndc": "00299384704",
        "name": "CLOBEX SHAMPOO",
        "brand": "CLOBEX 0.05% Shampoo 24x4oz US",
        "dosage": "0.05%",
        "howSupplied": "118gm"
      },
      {
        "ndc": "00299591202",
        "name": "DIFFERIN LOTION",
        "brand": "DIFFERIN (ADAP) 0.1% Lotion 24x2oz US",
        "dosage": "0.05%",
        "howSupplied": "59ml"
      },
      {
        "ndc": "00299591825",
        "name": "DIFFERIN GEL 0.3%",
        "brand": "DIFFERIN (ADAP) 0.3% Gel+Pump 24x45g US",
        "dosage": "0.3%",
        "howSupplied": "45gm"
      },
      {
        "ndc": "00299201210",
        "name": "VECTICAL",
        "brand": "VECTICAL (Calcitriol) Oint 24x100g US",
        "dosage": "3MCG/GM",
        "howSupplied": "100gm"
      },
      {
        "ndc": "00299594530",
        "name": "TWYNEO CREAM",
        "brand": "TWYNEO CREAM",
        "dosage": "30g in 1 BOTTLE",
        "howSupplied": "30g"
      },
      {
        "ndc": "00299589030",
        "name": "EPSOLAY CREAM",
        "brand": "EPSOLAY CREAM",
        "dosage": "30g in 1 BOTTLE",
        "howSupplied": "30g"
      }
    ],
    "DMO": [
      {
        "ndc": "01000000000",
        "name": "Program 1",
        "brand": "Program 1",
        "dosage": "0.01%",
        "howSupplied": "120ml"
      },
      {
        "ndc": "02000000000",
        "name": "Program 2",
        "brand": "Program 2",
        "dosage": "0.05%",
        "howSupplied": "125ml"
      },
      {
        "ndc": "03000000000",
        "name": "Program 3",
        "brand": "Program 3",
        "dosage": "40mg",
        "howSupplied": "40 MCG CAP"
      },
      {
        "ndc": "04000000000",
        "name": "Program 4",
        "brand": "Program 4",
        "dosage": "40mg",
        "howSupplied": "40 MCG CAP"
      },
      {
        "ndc": "05000000000",
        "name": "Program 5",
        "brand": "Program 5",
        "dosage": "0.33%",
        "howSupplied": "30gm"
      },
      {
        "ndc": "06000000000",
        "name": "Program 6",
        "brand": "Program 6",
        "dosage": "0.3-2.5%",
        "howSupplied": "45gm"
      },
      {
        "ndc": "07000000000",
        "name": "Program 7",
        "brand": "Program 7",
        "dosage": "1%",
        "howSupplied": "45gm"
      },
      {
        "ndc": "08000000000",
        "name": "Program 8",
        "brand": "Program 8",
        "dosage": "0.01-0.05-4%",
        "howSupplied": "30gm"
      },
      {
        "ndc": "09000000000",
        "name": "Program 9",
        "brand": "Program 9",
        "dosage": "0.005%",
        "howSupplied": "45gm"
      },
      {
        "ndc": "10000000000",
        "name": "Program 10",
        "brand": "Program 10",
        "dosage": "0.05%",
        "howSupplied": "118gm"
      },
      {
        "ndc": "11000000000",
        "name": "Program 11",
        "brand": "Program 11",
        "dosage": "0.05%",
        "howSupplied": "59ml"
      },
      {
        "ndc": "12000000000",
        "name": "Program 12",
        "brand": "Program 12",
        "dosage": "0.3%",
        "howSupplied": "45gm"
      },
      {
        "ndc": "13000000000",
        "name": "Program 13",
        "brand": "Program 13",
        "dosage": "3MCG/GM",
        "howSupplied": "100gm"
      },
      {
        "ndc": "14000000000",
        "name": "Program 14",
        "brand": "Program 14",
        "dosage": "30g in 1 BOTTLE",
        "howSupplied": "30g"
      },
      {
        "ndc": "15000000000",
        "name": "Program 15",
        "brand": "Program 15",
        "dosage": "30g in 1 BOTTLE",
        "howSupplied": "30g"
      }
    ],
    "BHC": [
      {
        "ndc": "00187209845",
        "name": "Arazlo 45g",
        "howSupplied": "45g"
      },
      {
        "ndc": "00187000260",
        "name": "Bryhali 60g",
        "howSupplied": "60g"
      },
      {
        "ndc": "00187000201",
        "name": "Bryhali 100g",
        "howSupplied": "100g"
      },
      {
        "ndc": "00187000625",
        "name": "Cabtreo"
      },
      {
        "ndc": "00187065301",
        "name": "Duobrii"
      },
      {
        "ndc": "00187540004",
        "name": "Jublia 4ml",
        "howSupplied": "4ml"
      },
      {
        "ndc": "00187540008",
        "name": "Jublia 8ml",
        "howSupplied": "8ml"
      },
      {
        "ndc": "99207085060",
        "name": "Luzu 60g",
        "howSupplied": "60g"
      },
      {
        "ndc": "00187520260",
        "name": "Noritate Cream 60g",
        "howSupplied": "60g"
      },
      {
        "ndc": "00187305050",
        "name": "Onexton 50g",
        "howSupplied": "50g"
      },
      {
        "ndc": "00187514850",
        "name": "RETIN-A MICRO 0.08% GEL 50g",
        "howSupplied": "50g"
      },
      {
        "ndc": "00187514650",
        "name": "RETIN-A MICRO 0.06% GEL 50g",
        "howSupplied": "50g"
      },
      {
        "ndc": "00187510401",
        "name": "Xerese 5g",
        "howSupplied": "5g"
      },
      {
        "ndc": "00187000402",
        "name": "SILIQ"
      }
    ],
    "INCY": [
      {
        "ndc": "50881000705",
        "name": "OPZELURA 60 g",
        "brand": "OPZELURA 60 g",
        "dosage": "60g in 1 TUBE",
        "howSupplied": "60g"
      }
    ]
  },
  "COPAY_REQUEST_VALIDATION_REJECT_CODES": {
    "RC_1": {
      "name": "Insurance Is Not Eligible",
      "description": "Program is only available to patients with commercial insurance"
    },
    "RC_2": {
      "name": "Copay Exceed Limit",
      "description": "Primary copay amount exceeded program limit"
    },
    "RC_3": {
      "name": "Age Exceed Limit",
      "description": "Patient age is outside of program age limit"
    },
    "RC_4": {
      "name": "Does not have Allowable Reject Codes",
      "description": "Claim contains an ineligible reject code"
    },
    "RC_5": {
      "name": "Has not Allowable Reject Codes",
      "description": "Claim contains an ineligible reject code"
    },
    "RC_6": {
      "name": "Drug Ndc Is Not In Program",
      "description": "Specified product is not included in program"
    }
  },
  "SELF_SERVE": {
    "steps": {
      "OccValidationComponent": {
        "name": "OCC Validation",
        "showInTimeline": true,
        "timeLineTemplate": {
          "requestContext": [
            {
              "valueTemplate": "occ"
            },
            {
              "valueTemplate": "occ === '03' && primaryRejectCodes ? primaryRejectCodes.join(', ') : 'Patient Responsibility: $' + primaryCopayAmount"
            }
          ]
        }
      },
      "DaysSupplyCheckComponent": {
        "name": "Days Supply",
        "showInTimeline": true,
        "timeLineTemplate": {
          "requestContext": [
            {
              "valueTemplate": "productDaysSupply"
            }
          ]
        }
      },
      "QuantityDispensedCheckComponent": {
        "name": "Quantity Dispensed",
        "showInTimeline": true,
        "timeLineTemplate": {
          "requestContext": [
            {
              "valueTemplate": "quantityDispensed"
            }
          ]
        }
      },
      "PharmacyStateExclusionCheckComponent": {
        "name": "Pharmacy State Exclussion",
        "showInTimeline": false,
        "timeLineTemplate": {
          "requestContext": [
            {
              "valueTemplate": "pharmacyState"
            }
          ]
        }
      },
      "NdcMaxCopayCheckComponent": {
        "name": "NDC based Max Copay",
        "showInTimeline": false,
        "timeLineTemplate": {
          "requestContext": [
            {
              "label": "OCC",
              "valueTemplate": "occ"
            },
            {
              "label": "Copay Amount",
              "valueTemplate": "(!primaryRejectCodes && primaryCopayAmount !== undefined)? '' + primaryCopayAmount : undefined"
            }
          ]
        }
      },
      "PaymentAdjudicationTask": {
        "name": "Claim Adjudication",
        "showInTimeline": true,
        "timeLineTemplate": {
          "requestContext": [
            {
              "label": "Pharmacy Request",
              "valueTemplate": "'$' + (responseInfo && responseInfo.submitted_total)"
            },
            {
              "label": "Manufacturer Max Benefit",
              "valueTemplate": "'$' + maxBenefit"
            },
            {
              "label": "Patient OOP",
              "valueTemplate": "'$' + (responseInfo && responseInfo.patient_pay_amount)"
            },
            {
              "label": "Manufacturer Contribution",
              "valueTemplate": "'$' + (responseInfo && responseInfo.total_amount_paid)"
            }
          ]
        }
      },
      "RetailInsuranceCheckComponent": {
        "name": "Govt attest",
        "showInTimeline": true,
        "timeLineTemplate": {
          "requestContext": [
            {
              "valueTemplate": "retailInsuranceCheckResult"
            }
          ]
        }
      },
      "PrescriberNpiComponent": {
        "name": "Prescriber NPI",
        "showInTimeline": true,
        "timeLineTemplate": {
          "requestContext": [
            {
              "label": "NPI",
              "valueTemplate": "prescriberNpi"
            }
          ]
        }
      },
      "RetailInsuranceInfoComponent": {
        "name": "Insurance",
        "showInTimeline": true,
        "timeLineTemplate": {
          "requestContext": [
            {
              "valueTemplate": "payorName ? ([primaryBin, primaryPcn, primaryGroup]).join('-') : primaryBin"
            },
            {
              "label": "PBM",
              "valueTemplate": "pbmVendor"
            },
            {
              "label": "Plan Sponsor",
              "valueTemplate": "payorName"
            }
          ]
        }
      },
      "AdditionalRxInfoComponent": {
        "name": "Rx Info",
        "showInTimeline": true,
        "timeLineTemplate": {
          "requestContext": [
            {
              "label": "Rx Number",
              "valueTemplate": "rxNumber"
            },
            {
              "label": "Fill Number",
              "valueTemplate": "fillNumber"
            },
            {
              "label": "Quantity",
              "valueTemplate": "quantityDispensed !== undefined ? '' + quantityDispensed : undefined"
            },
            {
              "label": "Patient State",
              "valueTemplate": "patientState"
            }
          ]
        }
      },
      "RetailBvCheckComponent": {
        "name": "BV Information",
        "showInTimeline": true,
        "timeLineTemplate": {
          "requestContext": [
            {
              "label": "Copay Amount",
              "valueTemplate": "(!primaryRejectCodes && primaryCopayAmount !== undefined) ? '' + primaryCopayAmount : undefined"
            },
            {
              "label": "Max Benefit",
              "valueTemplate": "(!primaryRejectCodes && primaryCopayAmount !== undefined && !!maxBenefit && !!maxBenefit.toFixed) ? maxBenefit.toFixed(2) : undefined"
            },
            {
              "label": "Reject Codes",
              "valueTemplate": "primaryRejectCodes && primaryRejectCodes.join(', ')"
            }
          ]
        }
      },
      "SelectBenefitsOptionComponent": {
        "name": "BV Information",
        "showInTimeline": true,
        "timeLineTemplate": {
          "requestContext": [
            {
              "valueTemplate": "selectedBenefit"
            },
            {
              "label": "Benefit Amount",
              "valueTemplate": "benefitAmount || '0'"
            }
          ]
        }
      },
      "RetailCodeDisplayComponent": {
        "name": "Dispensed Code",
        "showInTimeline": true,
        "timeLineTemplate": {
          "requestContext": [
            {
              "label": "BIN",
              "valueTemplate": "dispensedCodes && copayCodeBin"
            },
            {
              "label": "PCN",
              "valueTemplate": "dispensedCodes && copayCodePcn"
            },
            {
              "label": "Group",
              "valueTemplate": "dispensedCodes && copayCodeGroup"
            },
            {
              "label": "Member ID",
              "valueTemplate": "dispensedCodes && dispensedCodes[0]"
            },
            {
              "label": "Consignment Hub",
              "valueTemplate": "consignmentAuthorizationId && consignmentPharmacyName || consignmentPharmacyNpi"
            },
            {
              "label": "Consignment ID",
              "valueTemplate": "consignmentAuthorizationId"
            }
          ]
        }
      },
      "RetailInsuranceInfoNoCheckComponent": {
        "name": "Insurance",
        "showInTimeline": true,
        "timeLineTemplate": {
          "requestContext": [
            {
              "valueTemplate": "([primaryBin, primaryPcn, primaryGroup]).join('-')"
            },
            {
              "valueTemplate": "([pbmVendor, payorName, popDesc]).join('-')"
            }
          ]
        }
      },
      "HowDidWeDoComponent": {
        "name": "How Did We Do",
        "showInTimeline": false
      },
      "RetailInsuranceInfoNoCheckWithCodeUploadComponent": {
        "name": "Retail Insurance Info No Check With Code Upload",
        "showInTimeline": false
      },
      "ThankYouComponent": {
        "name": "Thank You",
        "showInTimeline": false
      },
      "WeeklyBatchRequestCodesComponent": {
        "name": "Weekly Batch Request Codes",
        "showInTimeline": false
      }
    }
  }
}
